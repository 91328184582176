
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.esri-view .esri-view-surface--inset-outline:focus::after {
    outline: 0 !important;
}

/*tr:nth-child(even) {*/
/*  background-color: #D6EEEE;*/
/*}*/

.styled-table {
    border-collapse: collapse;
    margin: 4px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    /*min-width: 400px;*/
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    /*text-align: center;*/
    margin-left: auto;
    margin-right: auto;
}

/*.styled-table thead tr {*/
/*    !*background-color: #009879;*!*/
/*    background-color: #982218;*/
/*    color: #ffffff;*/
/*    text-align: left;*/
/*}*/

.styled-table th,
.styled-table td {
    padding: 2px 5px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

/*.styled-table tbody tr:last-of-type {*/
/*    border-bottom: 2px solid #009879;*/
/*}*/

/*.styled-table tbody tr.active-row {*/
/*    font-weight: bolder;*/
/*    color: #8DB300;*/
/*}*/

/*.esri-popup__main-container {*/
/*    height: 620px !important;*/
/*    width: 405px;*/

/*}*/

.esri-view-width-xlarge .esri-popup__main-container,
.esri-view-width-large .esri-popup__main-container,
.esri-view-width-medium .esri-popup__main-container
{
  max-height: 400px !important;
  max-width: 450px !important;
}