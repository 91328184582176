/*.app {*/
/*    position: absolute;*/
/*}*/

.menu {
    display: grid;
    grid-template-columns: 250px auto;
    height: 50px;
    width: 98%;
    z-index: 0;
}
/*logo enundation*/
/*.logo {*/
/*    width: 200px;*/
/*    margin: 0.9375rem;*/
/*}*/

.logo {
    height: 45px;
    margin-top: 3px;
    margin-left: 50px;
}
.slider {
    top: 5px;
    /*width: calc(100% - 500px);*/
}

.map {
    top: 50px;
    height: calc(100vh - 50px);
    width: 100vw;
    z-index: 0;
}
